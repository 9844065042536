export const environment = {
  production: true,
  sentryUrl: 'https://c77d11a8c2d842c293f9b992e221bca6@o508805.ingest.sentry.io/4504095855083520',
  apiUrl: 'https://backend.lideresca.org/api/',
  shareUrl: 'https://backend.lideresca.org/share',
  firebase: {
    apiKey: 'AIzaSyCb4nEX8wJEZuEXkf4uuucmHCxMgWuK5Zs',
    authDomain: 'lds-sudca-lideres.firebaseapp.com',
    databaseURL: 'https://lds-sudca-lideres.firebaseio.com',
    projectId: 'lds-sudca-lideres',
    storageBucket: 'lds-sudca-lideres.appspot.com',
    messagingSenderId: '68061270820',
    appId: '1:68061270820:web:69c4d046228f19d8cb59b1',
    measurementId: 'G-F2WESRHQ8Q',
  },
  auth_config: {
    client_id: '0oang9ahg2g6cqK95357',
    server_host: 'https://id.churchofjesuschrist.org/oauth2/default',
    redirect_url: 'lideresca://callback',
    end_session_redirect_url: 'lideresca://logout',
    scopes: 'openid profile offline_access',
    pkce: true,
    loadUserInfo: true
    },
};
