// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-actions {
  position: absolute;
  top: 16px;
  right: 10px;
}
app-actions ion-icon {
  font-size: 20px;
}
app-actions ion-button[expand=full] {
  width: 31px;
  margin-bottom: 0;
}
app-actions ion-button[expand=full]:last-child {
  width: auto;
  min-width: 31px;
  text-align: left;
  text-decoration: none;
}
app-actions ion-button[expand=full]:last-child span {
  padding-right: 31px;
}
app-actions ion-button[expand=full]:last-child ion-icon {
  font-size: 17px;
}
app-actions app-favorite ion-icon {
  font-size: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/actions/actions.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,gBAAA;AAEJ;AAAE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AADI;EACE,eAAA;AAGN;AACI;EACI,0BAAA;AACR","sourcesContent":["app-actions {\n  position: absolute;\n  top: 16px;\n  right: 10px;\n  ion-icon{\n    font-size: 20px;\n  }\n  ion-button[expand='full'] {\n    width: 31px;\n    margin-bottom: 0;\n  }\n  ion-button[expand='full']:last-child {\n    width: auto;\n    min-width: 31px;\n    text-align: left;\n    text-decoration: none;\n    span {\n      padding-right: 31px;\n    }\n    ion-icon{\n      font-size: 17px;\n    }\n  }\n  app-favorite {\n    ion-icon{\n        font-size:20px!important;\n      }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
