//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import { get, set } from './services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService as AuthServices } from './services/auth.service';
import { IAuthAction, AuthActions, AuthService } from 'ionic-appauth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  lang = 'es';
  constructor(
    private auth: AuthService,
    private router: Router,
    private authService: AuthServices,
    private translateService: TranslateService
  ) {
    this.lang = localStorage.getItem('lang') || 'es';
    this.translateService.onLangChange.subscribe((data) => {
      this.lang = data.lang;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request?.url.includes('id.churchofjesuschrist.org/oauth2')) {
      if (!request?.url.includes('token')) {
        const token = localStorage.getItem('token');
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      }
    } else {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    if (!request.headers.has('Accept-Language')) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': this.lang,
        },
      });
    }
    //
    request = request.clone({
      headers: request.headers.set('Accept-Language', this.lang),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log("event--->>>", event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.error.message && error.error.message === 'Unauthenticated.') {
          this.auth.signIn();

          //this.authService.cleanLocalStorage();
          //this.router.navigateByUrl('/landing');
        }
        return throwError(error);
      })
    );
  }
}
