import {
  Component,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  IonContent,
  Platform,
  MenuController,
  NavController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ToastService } from './services/toast.service';

import { get, set } from './services/storage.service';

import { App } from '@capacitor/app';

import { PushNotificationsService } from './services/push-notification.service';
import { PushNotificationsBrowserService } from './services/push-notification-browser.service';
import { RestService } from './services/rest.service';

import { VersionService } from './services/version.service';
import { AnalyticsService } from './services/analytics.service';
import { ShareService } from './services/share.service';
import { EventEmitterService } from './services/event-emitter.service';
import { Browser } from '@capacitor/browser';
import { IAuthAction, AuthActions, AuthService } from 'ionic-appauth';
import { Subscription } from 'rxjs';
import { AuthService as AuthServices } from './services/auth.service';

import { take } from 'rxjs/operators';

import { ActionSheetController } from '@ionic/angular';

import * as moment from 'moment';
import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@capacitor/splash-screen';
import * as LiveUpdates from '@capacitor/live-updates';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  @ViewChild(IonContent, { static: false }) menu: IonContent;
  isStatusBarLight = false;
  public version = null;
  public custom_buttons = [];
  clicks = 0;
  loggedIn = false;
  user: any;
  events$ = this.auth.events$;
  sub: Subscription;

  constructor(
    private eventEmitterService: EventEmitterService,
    private platform: Platform,
    private router: Router,
    private menuController: MenuController,
    private versionService: VersionService,
    private analyticsService: AnalyticsService,
    private pushNotificationsService: PushNotificationsService,
    private pushNotificationsBrowserService: PushNotificationsBrowserService,
    private zone: NgZone,
    private restService: RestService,
    private shareService: ShareService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private navController: NavController,
    private actionSheetCtrl: ActionSheetController,
    private authServices: AuthServices,
    private auth: AuthService
  ) {
    this.initializeApp();
  }
  async initializeApp() {
    this.translateService.setDefaultLang('es');
    if (Capacitor.isPluginAvailable('LiveUpdates')) {
      const result = await LiveUpdates.sync();
      if (result.activeApplicationPathChanged) {
        await LiveUpdates.reload();
      } else {
        await SplashScreen.hide();
      }
    }
    this.platform.ready().then(async () => {
      document.onclick = function (e: any) {
        e.preventDefault();
        if (e.target.tagName == 'A') {
          Browser.open({ toolbarColor: '#6AD0AE', url: e.target.href });
          return false;
        }
      };
      await this.auth.init();
      this.sub = this.auth.events$.subscribe((action) =>
        this.onSignInSuccess(action)
      );

      // Commenting splashScreen Hide, so it won't hide splashScreen before auth check
      //this.splashScreen.hide();
      if (Capacitor.isPluginAvailable('StatusBar')) {
        StatusBar.setStyle({ style: Style.Light });
      }

      let lang = localStorage.getItem('lang') || 'es';
      this.translateService.setDefaultLang(lang);

      // Trigger the push setup
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        this.pushNotificationsService.init();
      } else {
        this.pushNotificationsBrowserService.init();
      }
      this.versionService.checkUpdates();
      this.getVersion();

      get('DATA_Home').then((data) => {
        if (data) {
          this.custom_buttons = data.custom_buttons;
          for (let item of this.custom_buttons) {
            item.category = item.app_category.title;
          }
        }
      });
      this.eventEmitterService.get('custom_buttons').subscribe((data) => {
        this.custom_buttons = data;
        for (let item of this.custom_buttons) {
          item.category = item.app_category.title;
        }
        this.loggedIn = true;
        let name = localStorage.name || false;
        let email = localStorage.email || false;
        this.user = { name, email };
      });

      let authConfig = environment.auth_config;
      if (!this.platform.is('cordova')) {
        authConfig.redirect_url = window.location.origin + '/callback';
        authConfig.end_session_redirect_url =
          window.location.origin + '/logout';
      }
      /*  App.addListener('appUrlOpen', (data: any) => {
          if (data.url !== undefined) {
          this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            console.log(2,data);
            if ((data.url).indexOf(authConfig.redirect_url) === 0) {

          }else{

            const slug = data.url.split('lideresca:/').pop();
            console.log(3,slug);
            if (slug) {
              //slug.replace('post', 'post').replace('events', 'events').replace('playlists', 'music').replace('gallery', 'resources');
              this.router.navigateByUrl(slug);
            }
          }
            // If no match, do nothing - let regular routing
            // logic take over
          });
        }
        });
*/

      this.auth.token$.subscribe(async (responseToken) => {
        const token = localStorage.getItem('token') || null;
        const accessToken = localStorage.getItem('accessToken');
        console.log(responseToken.accessToken);
        console.log(token);
        if (
          responseToken &&
          responseToken.accessToken != token &&
          accessToken
        ) {
          localStorage.setItem('token', responseToken.accessToken);
          localStorage.setItem('expiresIn', responseToken.expiresIn.toString());
          localStorage.setItem('issuedAt', responseToken.issuedAt.toString());
          this.authServices
            .performRefreshToken()
            .pipe(take(1))
            .subscribe(
              // next:
              (status: boolean) => {
                // if (status) {
                // } else {
                //   console.log('logout???');
                //   this.translateService
                //     .get('auth.login.error')
                //     .subscribe(async (res) => {
                //       alert(res);
                //       await this.authService.logout();
                //       this.navCtrl.navigateRoot('/landing');
                //     });
                // }
              },
              (err: HttpErrorResponse) => {
                console.log(err);
                //this.navCtrl.navigateRoot('/landing');
                // this.translateService
                //   .get('auth.login.error')
                //   .subscribe(async (res) => {
                //     alert(res);
                //   });
              }
            );
        }
      });
      App.addListener('resume', async () => {
        await this.refreshToken();
      });
    });
    this.eventEmitterService.get('custom_buttons_open').subscribe((data) => {
      this.openCustomButton(data);
    });
  }

  public async refreshToken(): Promise<void> {
    await this.auth.refreshToken();
  }
  setLang(lang) {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    // this.pushNotificationsService.subscribeLangTopic(lang);
    window.location.reload();
  }
  public async getUserInfo(): Promise<void> {
    let user2 = await this.auth.loadUserInfo();
    this.user = user2;
  }
  private onSignInSuccess(action: IAuthAction) {
    if (action.action === AuthActions.SignOutSuccess) {
      this.navController.navigateRoot('landing');
    }

    if (action.action === AuthActions.SignInSuccess) {
      this.loggedIn = true;
    }

    if (action.action === AuthActions.SignInFailed) {
      this.loggedIn = false;
    }
  }

  async finishAccount() {
    this.translateService.get('MENU').subscribe(async (res2) => {
      const actionSheet = await this.actionSheetCtrl.create({
        buttons: [
          {
            text: res2.delete,
            role: 'destructive',
            handler: () => {
              this.authServices
                .removeAccount()
                .pipe(take(1))
                .subscribe(
                  // next:
                  (status: boolean) => {
                    if (status) {
                      this.navController.navigateRoot('landing');
                    } else {
                      alert('something bad happened');
                    }
                  },
                  (err: HttpErrorResponse) => {
                    console.log(err);
                    // this.translateService
                    //   .get('auth.login.error')
                    //   .subscribe(async (res) => {
                    //     alert(res);
                    //   });
                  }
                );
            },
          },
          {
            text: res2.cancel,
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
      });

      await actionSheet.present();
    });
  }

  getToken() {
    this.clicks++;
    if (this.clicks < 5) return false;
    this.clicks = 0;
    get('token')
      .then((result) => {
        alert(result);
        console.log(result);
      })
      .catch((err) => {});
  }
  async getVersion() {
    // const info = await App.getInfo();
    // this.version = info.version;
  }
  public openMenu() {
    this.menu.scrollToTop();
  }

  public async openCustomButton(data) {
    if (data.url.startsWith('http')) {
      data['loca_date_save'] = moment();
      data['type'] = 'resources';
      data['typeString'] = 'resources';
      get('DATA_LAST').then((ids) => {
        ids = ids ? ids : [];
        const index = ids.findIndex((item) => {
          return (
            item.id === data.id &&
            item.typeString === data.typeString &&
            item.categoryId === data.categoryId
          );
        });
        if (index > -1) {
          ids.splice(index, 1);
        }
        ids.push(data);
        set('DATA_LAST', ids);
      });

      await Browser.open({ toolbarColor: '#6AD0AE', url: data.url });
    } else {
      this.navController.navigateRoot(data.url);
    }
  }
  public share($event, item = null, type = 'app') {
    $event.preventDefault();
    $event.stopPropagation();
    this.shareService.share(item, type);
  }

  public async signOut() {
    if (!localStorage.inviteuser) {
      await this.authServices.logout();
      this.navController.navigateRoot('landing');
      await this.auth.signOut();
    } else {
      localStorage.removeItem('inviteuser');
      this.navController.navigateRoot('landing');
    }
  }
}
