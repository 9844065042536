// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-search-bar ion-menu-button svg {
  width: 22px !important;
  height: 38px !important;
  top: 3px;
  position: relative;
  left: 13px;
}
app-search-bar .badge {
  width: 13px !important;
  height: 13px !important;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  z-index: 10;
  background: red;
  right: -8px;
}
app-search-bar ion-searchbar {
  --border-radius: 15px !important;
  --background: #fff !important;
  --box-shadow: none !important;
  color: #a6b8be !important;
  --placeholder-opacity: 1 !important;
  font-size: 16px;
  --icon-color: #a6b8be !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
app-search-bar ion-searchbar ion-icon {
  left: auto !important;
  right: 14px !important;
}
app-search-bar ion-searchbar input {
  padding-inline-start: 20px !important;
  padding-inline-end: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/searchBar/searchBar.component.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,uBAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;AAAJ;AAEE;EACE,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,WAAA;AAAJ;AAEE;EACE,gCAAA;EACA,6BAAA;EACA,6BAAA;EACA,yBAAA;EACA,mCAAA;EACA,eAAA;EACA,gCAAA;EACA,+BAAA;EACA,4BAAA;AAAJ;AACI;EACE,qBAAA;EACA,sBAAA;AACN;AACI;EACE,qCAAA;EACA,mCAAA;AACN","sourcesContent":["app-search-bar {\n  ion-menu-button svg {\n    width: 22px !important;\n    height: 38px !important;\n    top: 3px;\n    position: relative;\n    left: 13px;\n  }\n  .badge {\n    width: 13px !important;\n    height: 13px !important;\n    border-radius: 50%;\n    border: 2px solid #fff;\n    position: absolute;\n    z-index: 10;\n    background: red;\n    right: -8px;\n  }\n  ion-searchbar {\n    --border-radius: 15px !important;\n    --background: #fff !important;\n    --box-shadow: none !important;\n    color: #a6b8be !important;\n    --placeholder-opacity: 1 !important;\n    font-size: 16px;\n    --icon-color: #a6b8be !important;\n    padding-bottom: 15px!important;\n    padding-top: 15px!important;\n    ion-icon {\n      left: auto !important;\n      right: 14px !important;\n    }\n    input {\n      padding-inline-start: 20px !important;\n      padding-inline-end: 40px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
