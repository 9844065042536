// http-data.servie.ts
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { get, set } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  retryTimes = 0;

  constructor(private http: HttpClient) {}
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  camelToSnakeCase = (str) =>
    str
      .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
      .replace('-', '');
  lowerCaseFirstLetter = ([first, ...rest], locale = navigator.language) =>
    first === undefined ? '' : first.toLowerCase(locale) + rest.join('');

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  //version app
  getVersion(type, version): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}version/check?os=${type}&version=${version}`
      )
      .pipe(
        retry(this.retryTimes),
        map((resp) => {
          let data = resp;
          return data;
        })
      );
  }

  getBase64(url) {
    return this.http.get(`${environment.apiUrl}base64?path=${url}`).pipe(
      retry(this.retryTimes),
      map((post) => {
        return post;
      })
    );
  }
  // Get Courses data

  getDataHome(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}home-content`).pipe(
      retry(this.retryTimes),
      map((response) => {
        for (let item of response.data.featured_content) {
          item.type = this.camelToSnakeCase(item.type);
          item['typeString'] = 'post';
          item['categoryId'] = item.type;
          item['type'] = 'post';

          item = this.setRoutes(item);
        }
        return response.data;
      }),
      catchError(this.handleError)
    );
  }
  getContent(path): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${path}`).pipe(
      retry(this.retryTimes),
      map((response) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }
  // Get Courses data
  getContentDetail(path, typeString, categoryId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${path}`).pipe(
      retry(this.retryTimes),
      map((response) => {
        if (response.data) {
          response.data['type'] = typeString;
          response.data['typeString'] = typeString;
          response.data['categoryId'] = categoryId;
          response.data = this.setRoutes(response.data);

          if (response.data.related) {
            response.data.related = this.setRoutes(response.data.related);
          }
        }
        return response;
      }),
      catchError(this.handleError)
    );
  }
  // Get Courses data
  getSearchData(q, type, page = 1, limit = 20): Observable<any> {
    return this.http
      .get<any>(
        `${
          environment.apiUrl
        }search?page=${page}&limit=${limit}&query=${q}${type.replaceAll(
          '-',
          '_'
        )}`
      )
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          let data = [];
          for (let i of Object.keys(response.data)) {
            response.data[i].type = response.data[i].type.replaceAll('_', '-');

            if (!response.data[i]['categoryId']) {
              response.data[i]['categoryId'] = response.data[i].type;
              if (
                response.data[i]['type'] == 'article' ||
                response.data[i]['type'] == 'press-release' ||
                response.data[i]['type'] == 'external-content'
              ) {
                response.data[i]['categoryId'] = response.data[i]['type'];
                response.data[i]['type'] = 'post';
              }
            }
            response.data[i] = this.setRoutes(response.data[i]);

            data.push(response.data[i]);
          }

          let filters = [];

          for (let item of Object.keys(response.filters)) {
            var i = response.filters[item];
            filters.push(i.replaceAll('_', '-'));
          }
          response.filters = filters;
          response.data = data;

          return response;
        }),
        catchError(this.handleError)
      );
  }
  getNotifications(page = 1, limit = 20): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}notifications?page=${page}&limit=${limit}`
      )
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }
  readNotification(id): Observable<any> {
    let data = {
      read: true,
    };
    return this.http
      .post<any>(`${environment.apiUrl}notification/${id}/read-by-user`, data)
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
  sendForm(email, description): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}faq-form-request`, {
        email,
        description,
      })
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
  getTrainging(id): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}training/${id}/check-by-user`, {})
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
  checkTrainging(id, status): Observable<any> {
    let data = {
      status: status,
    };
    return this.http
      .post<any>(`${environment.apiUrl}training/${id}/toggle-by-user`, data)
      .pipe(
        retry(this.retryTimes),
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  public setRoutes(item) {
    if (item.type == 'faq' || item.type == 'faqs') {
      item['uri'] = '/faqs/' + item.category?.id + '/' + item.id;
    } else if (item.type == 'training' || item.type == 'trainings') {
      item['uri'] = '/trainings/' + item.category?.id + '/' + item.id;
    } else if (
      item.type == 'official_letter' ||
      item.type == 'official-letter' ||
      item.type == 'official-letters'
    ) {
      item['uri'] = '/official-letters/' + item.categoryId + '/' + item.id;
    } else if (item.type == 'article' || item.type == 'articles') {
      item['uri'] = '/posts/article/' + item.id;
    } else if (
      (item.type == 'post' || item.type == 'posts') &&
      item.categoryId === 'article'
    ) {
      item['uri'] = '/posts/article/' + item.id;
    } else if (
      (item.type == 'post' || item.type == 'posts') &&
      item.categoryId === 'press-release'
    ) {
      item['uri'] = '/posts/press-release/' + item.id;
    } else if (
      (item.type == 'post' || item.type == 'posts') &&
      item.categoryId === 'external-content'
    ) {
      item['uri'] = item.url;
    } else if (item.type == 'contact' || item.type == 'contacts') {
      item['uri'] = '/tabs/contacts/' + item.id;
    } else if (item.type == 'notification' || item.type == 'notifications') {
      item['uri'] = '/notifications/notifications/' + item.id;
    } else {
      item['uri'] = item.type + '/' + item.id;
    }

    if (
      item.type == 'faq' ||
      item.type == 'faqs' ||
      item.type == 'training' ||
      item.type == 'trainings' ||
      item.type == 'official-letter' ||
      item.type == 'official-letters'
    ) {
      item['image'] = item.category ? item.category?.image : '';
    } else if (item.type == 'contact' || item.type == 'contacts') {
      item['thumbnail'] = '/assets/icon/contacts.svg';
    } else {
      item['thumbnail'] = item.image;
    }
    if (item.type == 'planning_master_plan') {
      item['image'] = '/assets/icon/temple.png';
      item['uri'] = '/master-plan/' + item.id;
    }
    return item;
  }
}
