import { Injectable, NgZone, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { get, set } from './storage.service';
import { TranslateService } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { observeOn, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

import { App } from '@capacitor/app';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsBrowserService {
  messafingFirebase: firebase.messaging.Messaging;
  constructor(
    public translateService: TranslateService,
    public toastController: ToastController,
    public authService: AuthService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  init() {
    firebase.initializeApp(environment.firebase);
    this.messafingFirebase = firebase.messaging();
    this.requestPermission().then((token: string) => {
      if (token) {
        localStorage.setItem('fcmToken', token);
      }
    });
    // this.receiveMessage().subscribe(payload =>{
    //   console.log(payload)
    // })
    if (navigator && navigator.serviceWorker)
      navigator.serviceWorker.addEventListener(
        'message',
        this.onReceiveMsg.bind(this)
      );
  }

  onReceiveMsg(event) {
    const data = event.data.notification;
    if (event.data?.messageType == 'push-received') {
      this.presentToast(data);
    } else {
      let url = '';
      if (data['custom_url']) {
        url = data['custom_url'];
      } else {
        url = '/notifications/notifications/' + data['notification_id'];
      }
      this.router.navigate([url]);
    }
  }

  requestPermission = () => {
    return new Promise((resolve, reject) => {
      const perm = Notification.requestPermission().then(async (result) => {
        if (result === 'granted') {
          const tokenFirebase = await this.messafingFirebase.getToken();
          if (Capacitor.isNativePlatform()) {
            // FCM.subscribeTo({ topic: 'all' })
            //   .then((r) => console.log(`subscribed to topic`))
            //   .catch((err) => console.log(err));
          }
          resolve(tokenFirebase);
        } else {
          console.log('No permission for push granted');
          resolve('');
        }
      });
    });
  };
  // private messaginObservable = new Observable(observable=>{
  //   this.messafingFirebase.onMessage(payload => {
  //     console.log("new message received. ", payload);
  //     observable.next(payload)
  //   })
  // });
  // receiveMessage(){
  //   return this.messaginObservable;
  // }
  async presentToast(data) {
    this.translateService.get('FCM').subscribe(async (res) => {
      const toast = await this.toastController.create({
        cssClass: 'customToast',
        duration: 3000,
        header: data['title'],
        message: data['body'],
        position: 'bottom',
        buttons: [
          {
            text: res.go,
            handler: () => {
              if (data) {
                let url = '';
                if (data['custom_url']) {
                  url = data['custom_url'];
                } else {
                  url =
                    '/notifications/notifications/' + data['notification_id'];
                }
                this.ngZone.run(() => this.router.navigate([url])).then();
              }
            },
          },
        ],
      });
      toast.present();
    });
  }
}
