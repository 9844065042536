import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventEmitterService {
  private emitters: { [notificationName: string]: EventEmitter<any> } = {};

  get(notificationName: string) {
    if (!this.emitters[notificationName])
      this.emitters[notificationName] = new EventEmitter<any>();

    return this.emitters[notificationName];
  }
}
