import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
  {
    path: 'splash',
    loadChildren: () =>
      import('./pages/splash/splash.module').then((m) => m.SplashPageModule),
  },

  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/auth/landing/landing.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: 'callback',
    loadChildren: () =>
      import('./pages/auth/auth-callback/auth-callback.module').then(
        (m) => m.AuthCallbackPageModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/auth/end-session/end-session.module').then(
        (m) => m.EndSessionPageModule
      ),
  },

  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full',
      },
      {
        path: 'tabs',
        loadChildren: () =>
          import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
      },
      {
        path: 'master-plan',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/master-plan/master-plan.module').then(
                (m) => m.MasterPlanPageModule
              ),
          },
          {
            path: ':planningMasterPlanId',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                      import('./pages/master-plan/stakes/stakes.module').then(
                        (m) => m.StakesPageModule
                      ),
                  },
                  {
                    path: ':stakeId',
                    children: [
                      {
                        path: '',

                        loadChildren: () =>
                          import(
                            './pages/master-plan/stake-master-plan/stake-master-plan.module'
                          ).then((m) => m.StakeMasterPlanPageModule),
                      },

                      {
                        path: 'form',
                        children: [
                          {
                            path: '',
                            loadChildren: () =>
                              import(
                                './pages/master-plan/form/form.module'
                              ).then((m) => m.FormPageModule),
                          },
                          {
                            path: ':formId',
                            loadChildren: () =>
                              import(
                                './pages/master-plan/form/form.module'
                              ).then((m) => m.FormPageModule),
                          },
                        ],
                      },
                      {
                        path: 'group',
                        children: [
                          {
                            path: '',
                            loadChildren: () =>
                              import(
                                './pages/master-plan/group/group.module'
                              ).then((m) => m.GroupPageModule),
                          },
                          {
                            path: ':groupId',
                            loadChildren: () =>
                              import(
                                './pages/master-plan/group/group.module'
                              ).then((m) => m.GroupPageModule),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ':typeString/:categoryId/:itemId',
        loadChildren: () =>
          import('./pages/detail/detail.module').then(
            (m) => m.DetailPageModule
          ),
      },
      {
        path: 'faqs',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/faqs/faqs.module').then((m) => m.FaqsPageModule),
          },
          {
            path: 'form',
            loadChildren: () =>
              import('./pages/faqs/form/form.module').then(
                (m) => m.FormPageModule
              ),
          },
          {
            path: ':categoryId',
            loadChildren: () =>
              import('./pages/faqs/category/category.module').then(
                (m) => m.CategoryPageModule
              ),
          },
        ],
      },
      {
        path: 'trainings',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/trainings/trainings.module').then(
                (m) => m.TrainingsPageModule
              ),
          },
          {
            path: ':categoryId',
            loadChildren: () =>
              import('./pages/trainings/category/category.module').then(
                (m) => m.CategoryPageModule
              ),
          },
        ],
      },
      {
        path: 'official-letters',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/official-letters/official-letters.module').then(
                (m) => m.OfficialLettersPageModule
              ),
          },
        ],
      },
      {
        path: 'posts',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/posts/posts.module').then(
                (m) => m.PostsPageModule
              ),
          },
        ],
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
