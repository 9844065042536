// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-tags ul {
  list-style: none;
  overflow: hidden;
  margin-bottom: 14px !important;
}
app-tags li {
  margin-bottom: 8px !important;
  font-size: 12px;
  line-height: 16px;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 9px;
  float: left;
  padding: 5px 6px !important;
  margin-right: 9px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tags/tags.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,gBAAA;EACA,8BAAA;AAAR;AAGI;EACI,6BAAA;EACA,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,0CAAA;EACA,kBAAA;EACA,WAAA;EACA,2BAAA;EACA,4BAAA;AADR","sourcesContent":["app-tags {\n    ul {\n        list-style: none;\n        overflow: hidden;\n        margin-bottom: 14px!important;\n    }\n    \n    li {\n        margin-bottom: 8px!important;\n        font-size: 12px;\n        line-height: 16px;\n        color: var(--ion-color-primary);\n        border: 1px solid var(--ion-color-primary);\n        border-radius: 9px;\n        float: left;\n        padding: 5px 6px!important;\n        margin-right: 9px!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
